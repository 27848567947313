import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import OrgService from '@/service/org';

export class ProfessionalRelationEntityModel extends BaseEntityModel {
    @FormControl({
        label: '专业组',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    professionalName: string = undefined;
    professionalId: string = undefined;

    @FormControl({
        label: '受理组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: OrgService.getSelectOrgTree,
        relationKeys: ['userId'],
        required: true
    } as FormControlOptionModel)
    orgId: string = undefined;
    orgName: string = undefined;

    @FormControl({
        label: '受理人',
        type: FormControlType.SELECT,
        relationPath: `${WORKORDER_BASE_REQUEST_PATH}/common/assigners/list`,
        required: false
    } as FormControlOptionModel)
    userId: string = undefined;
    userName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '专业组',
                dataIndex: 'professionalName'
            },
            {
                title: '受理组',
                dataIndex: 'orgName'
            },
            {
                title: '受理人',
                dataIndex: 'userName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toService() {
        return {
            id: this.id,
            professionalId: this.professionalId,
            orgId: this.orgId,
            userId: this.userId
        };
    }
}

export class ProfessionalRelationQueryModel extends QueryPageModel {
    professionalId: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
